import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { Box } from '@mui/system';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red'
  },
  positionStatic: {
    backgroundColor: 'green'
  }
});

const ResponsiveAppBar = () => {
  const classes = useStyles();
  return (
    <AppBar 
      sx={{
        maxWidth: '1000px', 
        left: '50%', 
        zIndex: '1', 
        transform: 'translate(-50%, 0)',
        backgroundColor: 'rgb(10, 10, 10, 0)'
      }} 
      elevation={0}
      position='absolute'
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}/>
        <Button href="https://www.vietnamworks.com/" target = "_blank" color="inherit" sx={{ fontSize: '13px', fontFamily: 'Blokletters_03', color: 'rgba(52, 52, 52, 1)'}}>Our Games</Button>
        <Button href="https://www.vietnamworks.com/" target = "_blank" color="inherit" sx={{ fontSize: '13px', fontFamily: 'Blokletters_03', color: 'rgba(52, 52, 52, 1)'}}>History</Button>
        <Button href="https://www.vietnamworks.com/" target = "_blank" color="inherit" sx={{ fontSize: '13px', fontFamily: 'Blokletters_03', color: 'rgba(52, 52, 52, 1)'}}>Career</Button>
        <Button href="https://www.vietnamworks.com/" target = "_blank" color="inherit" sx={{ fontSize: '13px', fontFamily: 'Blokletters_03', color: 'rgba(52, 52, 52, 1)'}}>Contact Us</Button>
      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;

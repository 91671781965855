import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import chest from '../images/chest.png';

function PrivatePolicy(props) {
    return (
    <Box sx={{
      backgroundColor: '#cde4cc',
      paddingBottom: '12px'
    }}>
      <Container maxWidth="false" sx={{paddingTop: '50px', width: "100%", maxWidth: "800px"}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={30}>
              Privacy Policy
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={30}>
              Small Games Ltd
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12}>
              Effective November 7, 2021<br/><br/>
              In this Privacy Policy we explain:<br/><br/>
              The ways we collect personal data about you and why we do so
              How we use your personal data, and
              The choices you have about your personal data.
              This Privacy Policy applies to all of Small Games Ltd games and other related services, collectively the Services. Please note that we may from time to time update this Privacy Policy by posting a new version on smallgames.fun. If we make any material changes, we will notify you by posting a notice in the Services prior to the change becoming effective. Your continued use of the Services after the effective date will be subject to the new Privacy Policy.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={30}>
              How to contact us
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12}>
              If you have questions about data protection or if you have any requests for resolving issues with your personal data, we encourage you to primarily contact us through our games so we can reply to you more quickly.<br/><br/>
              Email: anhtuvn933@gmail.com     
            </Typography>
          </Grid>  
          <Grid item xs={12}>
            <Typography fontSize={30}>
              The personal data we collect
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12}>
              Data you provide us<br/>

              Player nickname<br/>

              Other data you choose to give us (such as information in player support tickets)<br/>

              Data we collect automatically<br/>

              Your IP address and mobile device identifiers (such as your device ID, advertising ID, MAC address and IMEI)<br/>

              Data about your device, such as device name and operating system, browser type and language<br/>

              Coarse location data (country or city-specific)<br/>

              We also may create a game-specific ID for you when you use the Services<br/>

              Data we collect from our partners<br/>

              Data we receive if you link a third-party tool with the Services (such as login using your Game Center or Google account)<br/>

              Demographic data (such as to determine the coarse location of your IP address)<br/>

              Data to fight fraud (such as refund abuse in games)<br/>

              Data from platforms that the games run on (such as to verify payment)<br/>

              Data for advertising and analytics purposes, so we can keep improving our games<br/>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={30}>
              Why we collect your data
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12}>
              To make the Services functional (performance of contract)<br/>
              
              We process data necessary to<br/>

              Create accounts and allow you to play our games and use our Services<br/>

              Operate the Services<br/>

              Verify and confirm payments<br/>

              Provide and deliver products and services you request<br/>

              Send you communication related to the Services<br/>

              To make the Services more enjoyable for our players (legitimate interests)<br/>

              We collect and process necessary data to<br/>

              Update and develop player profiles<br/>

              Develop and improve the Services and player experience<br/>

              Manage our relationship with you<br/>

              Provide social features as part of the Services<br/>

              Customize your Service experience<br/>

              Respond to your comments and questions and provide player support<br/>

              Send you related information, such as updates, security alerts, and support messages<br/>

              Enable you to communicate with other players<br/>

              To keep the Services safe and fair (legitimate interests)<br/>

              Ensuring a level playing field in the Services is a top priority for us.<br/>

              In order to keep the Services and their social features safe and fair, to fight fraud and ensure acceptable use otherwise, we have a legitimate interest to process necessary data to<br/>

              Analyze and monitor use of the Services and its social features<br/>

              Moderate chats either automatically or manually<br/>

              Take action against fraudulent or misbehaving players<br/>

              To show personalized advertisements (consent)<br/>

              With your separate consent we collect, process and transfer to our third-party partners personal data to:<br/>

              Track the content you access in connection with the Services and your online behavior<br/>

              Deliver, target and improve our advertising and the Services<br/>

              To analyze, profile and segment anonymized data<br/>

              In all of the above cases and purposes, we may analyze, profile and segment all collected data in anonymized form.<br/>

              With your consent<br/>

              With your consent, we may process your data for additional purposes, such as using your location based on your IP address to show you local events.<br/>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={30}>
              Who can access your data
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12}>
                Apart from Small Games Ltd, your data can be accessed by others in the following situations:<br/>

                Other players and users<br/>

                Social features are a core component of our games. Other players and users may, for example, see your profile data, in-game activities and read the messages you have posted.<br/>

                Partners working for Small Games Ltd<br/>

                Small Games Ltd has partners to perform certain services on our behalf. These partners process your data only at and according to Small Games Ltd's instructions in order to provide the Service, such as hosting, player support, advertising, analytics and fraud prevention.<br/>

                Other companies and public authorities<br/>

                In order to combat fraud and illegal activity, we may exchange data with other companies and organizations and provide it to public authorities in response to lawful requests.<br/>

                We may also disclose your data based on your consent, to comply with the law or to protect the rights, property or safety of us, our players or others.<br/>

                Advertising and social media partners<br/>

                The Services include features from our partners, such as social media interaction tools and in-game advertising. A list of these partners is available at Small Games Ltd partners. These partners may access your data and operate under their own privacy policies. We encourage you to check their privacy policies to learn more about their data processing practices.<br/>

                International data transfers<br/>

                Our Services are global by nature and your data can therefore be transferred to anywhere in the world. Because different countries may have different data protection laws than your own country, we take steps to ensure adequate safeguards are in place to protect your data as explained in this Privacy Policy. Adequate safeguards to ensure your rights include utilizing and enforcing the Standard Contractual Clauses approved by the EU Commission.<br/>
            </Typography>
          </Grid>  
          <Grid item xs={12}>
            <Typography fontSize={30}>
              Your rights and options
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12}>
              Your rights regarding your personal data<br/>

              You have the right to a copy of your personal information to be supplied to you in an electronic format upon request.<br/>

              You also have the right to correct your data, have your data deleted, object to how we use or share your data, and the right to restrict how we use or share your data. If certain personal data is processed based on consent given by you, such consent can also be withdrawn by you at any time, for example in the applicable game's settings.<br/>

              We will respond to all requests within a reasonable time frame. If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact your local data protection authority within the European Economic Area for unresolved complaints.<br/>

              You can contact us at anhtuvn933@gmail.com.<br/>

              Opt-out of targeted advertising<br/>

              You can always opt-out of directed advertising in our games by checking the privacy settings of your device. Please review your device's user manual on how to do so. For more information, see also: Small Games Ltd partners<br/>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={30}>
              How we protect your data
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12}>
              Security Safeguards<br/>

              In order to help ensure a secure and safe player experience, we are continuously developing and implementing administrative, technical and physical security measures to protect your data from unauthorized access or against loss, misuse or alteration.<br/>

              Data retention<br/>

              We retain your data for as long as your account is active or as needed to provide you the Services. We will for example periodically de-identify unused game accounts and we regularly review and anonymize unnecessary data.<br/>

              Note that if you ask us to remove your personal data, we will retain your data as necessary for our legitimate business interests, such as to comply with our legal obligations, resolve disputes, and enforce our agreements.<br/>

              Your personal data is deleted or anonymized automatically after two years of account inactivity. Certain data such as payment data may be stored for periods longer than this in accordance with applicable accounting laws.<br/>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={30}>
              Age limits
            </Typography>
          </Grid><Grid item xs={12}>
            <Typography fontSize={12}>
             We do not knowingly collect or solicit personal data about or direct or target interest-based advertising to anyone under the age of 13 or knowingly allow such persons to use our Services. If you are under 13, please do not send any data about yourself to us, such as your name, address, telephone number, or email address. No one under the age of 13 may provide any personal data. If we learn that we have collected personal data about a child under age 13, we will delete such data without delay. If you believe that we might have any data from or about a child under the age of 13, please contact us.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>);
}

export default PrivatePolicy;
import React, { Fragment } from "react";
import { BrowserRouter, Link, Route } from "react-router-dom";
import IntroductionNonogram from "./components/IntroductionNonogram";
import Token from "./components/Token";
import Tokenomic from "./components/Tokenomic";
import MyAppBarNonogram from "./components/MyAppBarNonogram";
import Roadmap from "./components/Roadmap";
import { ThemeProvider } from "@emotion/react";
import { Container, createMuiTheme, createTheme, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Team from "./components/Team";
import Advisor from "./components/Advisor";
import Investorpartner from "./components/Investorpartner";
import Securityaudit from "./components/Securityaudit";
import Sidebar from "./components/Sidebar";
import Sidebar2 from "./components/Sidebar2"
import Footer from "./components/Footer"
import PrivatePolicy from "./components/PrivatePolicy";

export default class App extends React.Component {
  constructor(props){
    super(props);
  }
  render() {
    return (
      <ThemeProvider theme={createMuiTheme({
        typography: {
          fontFamily: ['Blokletters_02', 'Blokletters_03'].join(',')
         }
        })}
       >
        <PrivatePolicy/>
        {/*<Token/>
        <Roadmap/>
        <Tokenomic/>
        <Team/>
        <Advisor/>
        <Investorpartner/>
        <Securityaudit/>
        <Sidebar2/>
        <Sidebar/>*/}
        <Footer/>
      </ThemeProvider>
    )
  }
}
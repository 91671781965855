import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import rm from '../images/rm.png';

function Roadmap(props) {
    return (
    <Box sx={{
      backgroundColor: '#cde4cc'
    }}>
      <Container sx={{width: '100%', maxWidth: '1400px', paddingTop: '150px'}} maxWidth='false'>
        <Container sx={{width: '100%'}} maxWidth='false'>
          <Typography fontSize={40} align='center'>
            ROAD MAP
          </Typography>
        </Container>
        <Container maxWidth='false' elevation={0} sx={{ maxWidth: '800px',
            paddingTop: '30px'
          }}>
              <Box component="img" src={rm} width="100%"/>
            {/*
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Paper elevation={0} sx={{backgroundColor:'rgba(52, 52, 52, 0)',
                paddingTop: '30px'
              }}>
                <Typography fontSize={30} sx={{textDecoration: 'underline'}}>
                  Q1-2022
                </Typography>
                <Typography fontSize={20} sx={{marginTop: '25px'}}>
                  -Project introduction
                </Typography>
                <Typography fontSize={20}>
                  -Private sale
                </Typography>
                <Typography fontSize={20}>
                  -testnet
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} md={3}>
              <Paper elevation={0} sx={{backgroundColor:'rgba(52, 52, 52, 0)',
                paddingTop: '30px'
              }}>
                <Typography fontSize={30} sx={{textDecoration: 'underline'}}>
                  Q2-2022
                </Typography>
                <Typography fontSize={20} sx={{marginTop: '25px'}}>
                  -Release webversion
                </Typography>
                <Typography fontSize={20}>
                  -mainnet
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} md={3}>
              <Paper elevation={0} sx={{backgroundColor:'rgba(52, 52, 52, 0)',
                paddingTop: '30px'
              }}>
                <Typography fontSize={30} sx={{textDecoration: 'underline'}}>
                  Q3-2022
                </Typography>
                <Typography fontSize={20} sx={{marginTop: '25px'}}>
                  -Hero upgrade
                </Typography>
                <Typography fontSize={20}>
                  -Marketplace
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} md={3}>
              <Paper elevation={0} sx={{backgroundColor:'rgba(52, 52, 52, 0)',
                paddingTop: '30px'
              }}>
                <Typography fontSize={30} sx={{textDecoration: 'underline'}}>
                  Q4-2022
                </Typography>
                <Typography fontSize={20} sx={{marginTop: '25px'}}>
                  -Dino Metaverse
                </Typography>
              </Paper>
            </Grid>
            </Grid>*/}
        </Container>
      </Container>
    </Box>);
}

export default Roadmap;
import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import tokenmetric from '../images/token-metric.png';
import Partition from "./Partition";

function Tokenomic(props) {
    return (
    <Box elevation={5} sx={{
      backgroundColor: '#cde4cc',
      paddingTop: '100px'
    }}>
      <Container sx={{paddingTop: '50px', maxWidth: "1300px", width: "100%"}} maxWidth="false">
        <Container sx={{width: '100%', mb: "18px"}} maxWidth='false'>
          <Typography fontSize={40} align='center'>
            TOKENOMIC
          </Typography>
        </Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{marginTop: '30px'}}>
            <img src={tokenmetric} width="100%"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{backgroundColor:'rgba(52, 52, 52, 0)',
              padding: '5px'
            }}>
              <Partition name="Private Sale" percent='8' detail="6% on TGE, cliff 1 month, then 6% monthly unlock"/>
              <Partition name="Partnership" percent='7' detail="Cliff 4 months, then 7% monthly unlock"/>
              <Partition name="Public Sale (IDO)" percent='3' detail="20% on TGE, then 20% monthly unlock"/>
              <Partition name="Core Team" percent='20' detail="Cliff 12 months, then 4% monthly unlock"/>
              <Partition name="Liquidity" percent='5' detail="40% on TGE, then 5% monthly unlock"/>
              <Partition name="Marketing" percent='10' detail="10% after 7 days, 3% monthly unlock"/>
              <Partition name="Play to Earn Reward" percent='40' detail="Start to unlock when game launch, 3% monthly unlock"/>
              <Partition name="Reserve" percent='7' detail="Cliff 3 months, then 5% monthly unlock"/>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>);
}

export default Tokenomic;
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

function Footer(props) {
    return (<Box sx={{
        backgroundColor: 'green',
        paddingTop: '20px',
        height: '40px'
      }}>
        <Typography align="center" fontSize={17}>© 2023 Small Games Ltd. All rights reserved.</Typography>
    </Box>);
}

export default Footer;